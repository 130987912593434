.edge-to-edge-image {
    margin-top: 2rem;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    color: color('white');
    padding: 5rem 1rem;
    z-index: 1;

    text-align: center;

    // add a backgroud to add more contrast against text
    .pane-paragraphs-item-field-banner-image {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        background: rgba(#000,0.5);
        top: 0;
        left: 0;
    }

    // place text above background
    .pane-paragraphs-item-field-long-text {
        position: relative;
        z-index: 10;
    }

    .field-long-text {
        * {
            font-weight: 100;
        }
        h2 {
            @media all and (min-width: $col-sm) {
                font-size: 3rem;
            }
        }        
        h3 {
            @media all and (min-width: $col-sm) {
                font-size: 2rem;
            }
        }
        p {
            font-size: 1.5rem;
        }

        a {
            color: color('red');
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0.25rem;

            &:hover,
            &:active,
            &:focus {
                color: color('blue');
            }
        }
    }
}
