.footer-wrapper {
  background: color('custom-gray-2') url('/sites/all/themes/boston_systems__solutions_theme/images-source/footer-bg.png') bottom center no-repeat;
  background-size: cover;
  padding: 5rem 0;
  color: color('white');

  * {
    color: color('white');
  }
}

// layout
.panels-flexible-four_column_layout {
  .panels-flexible-four_column_layout-inside {
    margin: 0 -1rem;
    @media all and (min-width: $col-sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    // remove default padding
    .panels-flexible-region-inside {
      padding: 0;
    }

    .panels-flexible-region  {
      padding: 0 1rem;
      float: none;
      width: auto;
      text-transform: uppercase;
      text-align: center;
      @media all and (min-width: $col-sm) and (max-width: $col-md - 1px) {
          flex: 0 0 33.33%;
          text-align: left;
      }
      @media all and (min-width: $col-md) {
          flex: 0 0 25%;
          text-align: left;
      }      
    }

    // logo and number
    .panels-flexible-region-first {
      @media all and (min-width: $col-md) {
        text-align: right;
      } 
    }

    // menus
    .panels-flexible-region-four_column_layout-two,
    .panels-flexible-region-four_column_layout-three {
      // menu titles
      .pane__title {
        display: inline-block;
        margin: .5rem 0;
        font-weight: 100;
        border-bottom: 1px solid color('red');
      }
      // menu links
      ul.menu {
        padding: 0;
        margin: 0;
        li  {
          list-style-type: none;
          list-style-image: none;
        }
        a {
          text-transform: none;
          text-decoration: none;
          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;

          }
        }
      }
    }

    // phone number and copyright
    .panels-flexible-region-last {
      @media all and (min-width: $col-sm) and (max-width: $col-md - 1px) {
        flex: 0 0 100%;
        text-align: left;
        text-align: center;
        border-top: 1px solid color('red');
        margin-top: 2rem;
        padding-top: 2rem;

      }
      @media all and (min-width: $col-md) {
        text-align: center;
        border-left: 1px solid color('red');
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      // phone
      h2 {
        margin: 0;
        font-size: 2rem;

        a {
          text-decoration: none;
          font-weight: 100;
        }
      }

      // copyright
      p,
      ul {
        font-size: 0.75rem;
        line-height: 1rem;
      }
      p {
        margin-bottom: 0;
      }
      ul.menu {
        padding: 0;
        margin: 0;
        li  {
          display: inline;
          list-style-type: none;
          list-style-image: none;

          &:first-of-type {
            a {
              border-right: 1px solid color('white');
            }
          }
        }
        a {
          text-decoration: none;
          padding: 0 0.5rem;
          display: inline-block;

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

  }
}