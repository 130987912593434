/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: "Alegreya Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #f2f2f2;
}

.not-front .main-content {
  background: #fff;
  background: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  line-height: 2rem;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #D10000;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1 {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.page-title {
  display: inline-block;
  background: #D10000;
  color: #fff;
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 1em;
  font-size: 1rem;
}

.page-title-wrapper {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 25px;
}

.page-title-wrapper.left-justify {
  text-align: left;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 5px 10px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
  background: #0072b9;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .button,
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    width: auto;
  }
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  background: #00436d;
  color: #fff;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='text'],
[type='search'],
[type='tel'],
[type='url'],
[type='email'],
[type='password'],
[type='date'],
[type='time'],
[type='number'] {
  width: 100%;
  padding: 10px;
  border: 0;
  background: #555555;
}

[type='text']:hover, [type='text']:active, [type='text']:focus,
[type='search']:hover,
[type='search']:active,
[type='search']:focus,
[type='tel']:hover,
[type='tel']:active,
[type='tel']:focus,
[type='url']:hover,
[type='url']:active,
[type='url']:focus,
[type='email']:hover,
[type='email']:active,
[type='email']:focus,
[type='password']:hover,
[type='password']:active,
[type='password']:focus,
[type='date']:hover,
[type='date']:active,
[type='date']:focus,
[type='time']:hover,
[type='time']:active,
[type='time']:focus,
[type='number']:hover,
[type='number']:active,
[type='number']:focus {
  background: #0072b9;
}

@media (min-width: 768px) {
  [type='text'],
  [type='search'],
  [type='tel'],
  [type='url'],
  [type='email'],
  [type='password'],
  [type='date'],
  [type='time'],
  [type='number'] {
    width: auto;
  }
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
  width: 100%;
  padding: 10px;
  border: 0;
  background: #555555;
}

textarea:hover, textarea:active, textarea:focus {
  background: #0072b9;
}

@media (min-width: 768px) {
  textarea {
    width: auto;
  }
}

.g-recaptcha {
  margin-top: 10px;
  margin-bottom: 10px;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  max-width: 1170px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.container, .panels-flexible-landing_page .panels-flexible-row-first > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container, .panels-flexible-landing_page .panels-flexible-row-first > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container, .panels-flexible-landing_page .panels-flexible-row-first > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container, .panels-flexible-landing_page .panels-flexible-row-first > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside {
  margin-left: -15px;
  margin-right: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-md-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-lg-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-sm-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside, .col-md-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside, .col-lg-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region {
  float: left;
}

.col-xs-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
    float: left;
  }
  .col-sm-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
    float: left;
  }
  .col-md-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
    float: left;
  }
  .col-lg-12, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region > div.inside,
  .panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region > div.inside {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.panels-flexible-landing_page .panels-flexible-row-first > div.inside:before,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside:before,
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row:before,
.container:after,
.panels-flexible-landing_page .panels-flexible-row-first > div.inside:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region:before,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region:before,
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside:before,
.row:after,
.panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.panels-flexible-landing_page .panels-flexible-row-first > div.inside:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row:after,
.container-fluid:after,
.row:after,
.panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region:after,
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

.row .panels-flexible-region, .panels-flexible-landing_page .panels-flexible-row-first > div.inside > div.panels-flexible-region .panels-flexible-region,
.panels-flexible-landing_page .panels-flexible-row-landing_page-2 > div.inside > div.panels-flexible-region .panels-flexible-region, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside .panels-flexible-region {
  padding-left: 15px;
  padding-right: 15px;
}

.main-content {
  padding-bottom: 25px;
}

/*********************/
/*** PANELS LAYOUT ***/
/*********************/
.panels-flexible-landing_page {
  background: #fff;
}

/********************/
/****** HEADER ******/
/********************/
.panels-flexible-landing_page .panels-flexible-row-first {
  margin-bottom: 50px;
  background: #000 url("../images-source/landing_page_mobile_bg.jpg") center top no-repeat;
  padding-top: 65px;
  min-height: 900px;
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .panels-flexible-row-first {
    background: #000 url("../images-source/landing_page_bg.jpg") center top no-repeat;
    padding-top: 160px;
    height: 900px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .panels-flexible-row-first {
    background: #000 url("../images-source/landing_page_bg.jpg") center top no-repeat;
    padding-top: 150px;
    height: 900px;
  }
}

@media all and (min-width: 1200px) {
  .panels-flexible-landing_page .panels-flexible-row-first {
    background: #000 url("../images-source/landing_page_bg.jpg") center top no-repeat;
    background-size: cover;
    padding-top: 180px;
    height: 800px;
  }
}

.panels-flexible-landing_page .pane-page-logo a {
  display: block;
  text-align: center;
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .pane-page-logo {
    margin-top: -15px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .pane-page-logo {
    margin-top: -15px;
  }
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .pane-page-logo {
    float: left;
    width: 200px;
    height: auto;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .pane-page-logo {
    float: left;
    width: 300px;
    height: auto;
  }
}

@media all and (min-width: 1200px) {
  .panels-flexible-landing_page .pane-page-logo {
    float: left;
    width: 380px;
    height: auto;
  }
}

.panels-flexible-landing_page .custom-page-title {
  text-align: center;
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .custom-page-title {
    margin-left: 100px;
    text-align: right;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .custom-page-title {
    margin-left: 300px;
    text-align: right;
  }
}

@media all and (min-width: 1200px) {
  .panels-flexible-landing_page .custom-page-title {
    margin-left: 450px;
    text-align: right;
  }
}

.panels-flexible-landing_page .custom-page-title p {
  color: #b91618;
  font-size: 1em;
  line-height: 1.2em;
}

.panels-flexible-landing_page .custom-page-title p:first-of-type {
  display: block;
}

@media all and (min-width: 768px) {
  .panels-flexible-landing_page .custom-page-title p:first-of-type {
    display: none;
  }
}

.panels-flexible-landing_page .custom-page-title p:last-of-type {
  display: none;
}

@media all and (min-width: 768px) {
  .panels-flexible-landing_page .custom-page-title p:last-of-type {
    display: block;
  }
}

@media all and (min-width: 768px) {
  .panels-flexible-landing_page .custom-page-title p {
    font-size: 1.5em;
    line-height: 1.4em;
  }
}

.panels-flexible-landing_page .custom-page-title a {
  color: #b91618;
}

.panels-flexible-landing_page .custom-page-title a:hover, .panels-flexible-landing_page .custom-page-title a:active {
  color: #0072b9;
}

.panels-flexible-landing_page .custom-page-title h1 {
  font-weight: 100;
  font-family: Arial, Helvetica, "Nimbus Sans L", sans-serif;
  color: #b91618;
  font-size: 4em;
  line-height: 1em;
  margin-top: .5em;
  margin-bottom: .5em;
}

.panels-flexible-landing_page .custom-page-title h1 span.black {
  color: #000;
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .custom-page-title h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 5em;
    line-height: 1em;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .custom-page-title h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 6em;
    line-height: 1em;
  }
}

@media all and (min-width: 1200px) {
  .panels-flexible-landing_page .custom-page-title h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 9em;
    line-height: 1em;
  }
}

.panels-flexible-landing_page .subhead-one,
.panels-flexible-landing_page .subhead-two {
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75);
}

.panels-flexible-landing_page .subhead-one h2,
.panels-flexible-landing_page .subhead-two h2 {
  font-size: 1.25em;
  line-height: 1.25em;
  font-weight: 100;
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .subhead-one h2,
  .panels-flexible-landing_page .subhead-two h2 {
    font-size: 2.7em;
    line-height: 1.25em;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .subhead-one h2,
  .panels-flexible-landing_page .subhead-two h2 {
    font-size: 2.7em;
    line-height: 1.25em;
  }
}

@media all and (min-width: 1200px) {
  .panels-flexible-landing_page .subhead-one h2,
  .panels-flexible-landing_page .subhead-two h2 {
    font-size: 2.7em;
    line-height: 1.25em;
  }
}

.panels-flexible-landing_page .subhead-one h2 strong,
.panels-flexible-landing_page .subhead-two h2 strong {
  font-weight: bold;
}

.panels-flexible-landing_page .subhead-one h2 em,
.panels-flexible-landing_page .subhead-two h2 em {
  text-transform: uppercase;
}

.panels-flexible-landing_page .subhead-one {
  clear: both;
  margin-top: 140px;
  margin-bottom: 50px;
}

.panels-flexible-landing_page .subhead-two h2 {
  text-transform: uppercase;
}

/*******************/
/****** PHONE ******/
/*******************/
.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first {
  text-align: center;
  color: #b91618;
  text-transform: uppercase;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first a {
  color: #b91618;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first a:link, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first a:visited {
  color: #b91618;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first a:hover, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first a:active, .panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first a:focus {
  color: #0072b9;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first p {
  font-size: 1.5em;
  line-height: 1em;
  letter-spacing: .3em;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-main-row > div.inside > div.panels-flexible-region-first img {
  margin-top: 50px;
  margin-bottom: 50px;
}

/********************/
/****** FOOTER ******/
/********************/
.landing-page {
  background: #000;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-2 {
  margin-top: 50px;
  background: #000;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.panels-flexible-landing_page .panels-flexible-row-landing_page-2 a {
  color: #fff;
}

/**********************/
/****** MESSAGES ******/
/**********************/
.panels-flexible-landing_page .messages--ok-color, .panels-flexible-landing_page .messages, .panels-flexible-landing_page .messages--status, .panels-flexible-landing_page .ok, .panels-flexible-landing_page .revision-current {
  background-color: #e6ffe6;
  color: green;
  border: 1px solid green;
}

.panels-flexible-landing_page .messages--ok-color .messages__icon path, .panels-flexible-landing_page .messages .messages__icon path, .panels-flexible-landing_page .messages--status .messages__icon path, .panels-flexible-landing_page .ok .messages__icon path, .panels-flexible-landing_page .revision-current .messages__icon path {
  fill: green;
}

.panels-flexible-landing_page .panels-flexible-row-first {
  position: relative;
  z-index: 9;
}

.panels-flexible-landing_page .pane-page-messages {
  position: absolute;
  left: 15px;
  right: 15px;
  width: 100%;
  z-index: 10;
  top: -60px;
}

.panels-flexible-landing_page .pane-page-messages .messages {
  margin: 0 auto;
}

.panels-flexible-landing_page .pane-page-messages .messages p {
  margin: 0;
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .panels-flexible-landing_page .pane-page-messages {
    top: -150px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .panels-flexible-landing_page .pane-page-messages {
    top: -130px;
  }
}

@media all and (min-width: 1200px) {
  .panels-flexible-landing_page .pane-page-messages {
    top: -120px;
  }
}

/******************/
/****** FORM ******/
/******************/
.panels-flexible-landing_page input[type="text"],
.panels-flexible-landing_page input[type="email"],
.panels-flexible-landing_page textarea {
  width: 100%;
  background: none;
  border: 1px solid #000;
  color: #000;
}

.panels-flexible-landing_page input[type="text"]:hover, .panels-flexible-landing_page input[type="text"]:active, .panels-flexible-landing_page input[type="text"]:focus,
.panels-flexible-landing_page input[type="email"]:hover,
.panels-flexible-landing_page input[type="email"]:active,
.panels-flexible-landing_page input[type="email"]:focus,
.panels-flexible-landing_page textarea:hover,
.panels-flexible-landing_page textarea:active,
.panels-flexible-landing_page textarea:focus {
  border: 1px solid #0072b9;
}

.panels-flexible-landing_page input[type="submit"] {
  width: 100%;
  border-radius: 0px;
  text-align: center;
  background: #b91618;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

.panels-flexible-landing_page input[type="submit"]:hover, .panels-flexible-landing_page input[type="submit"]:active, .panels-flexible-landing_page input[type="submit"]:focus {
  background: #0072b9;
}

body.front-page .banner,
body.front-page .page-title-wrapper {
  display: none;
}

.view-latest-blog-posts .view-content {
  margin: 0 -1rem;
}

@media all and (min-width: 768px) {
  .view-latest-blog-posts .view-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.view-latest-blog-posts .view-content .views-row {
  padding: 0 1rem;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .view-latest-blog-posts .view-content .views-row {
    flex: 0 0 50%;
  }
}

@media all and (min-width: 992px) {
  .view-latest-blog-posts .view-content .views-row {
    flex: 0 0 25%;
  }
}

.view-latest-blog-posts .view-footer {
  text-align: center;
}

.view-latest-blog-posts .view-footer h3 {
  text-align: center;
  font-weight: 100;
  font-size: 1.5rem;
}

.view-latest-blog-posts .view-footer h3 a {
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
}

.view-latest-blog-posts .view-footer h3 a:hover, .view-latest-blog-posts .view-footer h3 a:active, .view-latest-blog-posts .view-footer h3 a:focus {
  color: #0072b9;
}

.view-latest-blog-posts .view-header h2 {
  text-align: center;
  font-weight: 100;
  color: #D10000;
  font-size: 2rem;
}

.view-latest-blog-posts .view-content .field-image {
  overflow: hidden;
}

.view-latest-blog-posts .view-content .field-image a {
  display: block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}

.view-latest-blog-posts .view-content .field-image a:hover, .view-latest-blog-posts .view-content .field-image a:active, .view-latest-blog-posts .view-content .field-image a:focus {
  transform: scale(1.5, 1.5);
  transition: all 0.25s ease-in-out;
}

.view-latest-blog-posts .view-content h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.view-latest-blog-posts .view-content h2 a {
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
}

.view-latest-blog-posts .view-content h2 a:hover, .view-latest-blog-posts .view-content h2 a:active, .view-latest-blog-posts .view-content h2 a:focus {
  color: #0072b9;
}

.view-latest-blog-posts .node-latest-blog-posts {
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media all and (min-width: 768px) {
  .view-latest-blog-posts .node-latest-blog-posts {
    text-align: left;
  }
}

.view-latest-blog-posts .node-latest-blog-posts .pane-node-links {
  margin-top: auto;
}

.view-latest-blog-posts .node-latest-blog-posts .pane-node-links a {
  color: #D10000;
  text-decoration: none;
  text-transform: uppercase;
}

.view-latest-blog-posts .node-latest-blog-posts .pane-node-links a:hover, .view-latest-blog-posts .node-latest-blog-posts .pane-node-links a:active, .view-latest-blog-posts .node-latest-blog-posts .pane-node-links a:focus {
  color: #0072b9;
}

.view-blog .view-content h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.view-blog .view-content h2 a {
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
}

.view-blog .view-content h2 a:hover, .view-blog .view-content h2 a:active, .view-blog .view-content h2 a:focus {
  color: #0072b9;
}

.view-blog .view-content .pane-node-links a {
  color: #D10000;
  text-decoration: none;
  text-transform: uppercase;
}

.view-blog .view-content .pane-node-links a:hover, .view-blog .view-content .pane-node-links a:active, .view-blog .view-content .pane-node-links a:focus {
  color: #0072b9;
}

body.section-tags .panel-pane h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

body.section-tags .panel-pane h2 a {
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
}

body.section-tags .panel-pane h2 a:hover, body.section-tags .panel-pane h2 a:active, body.section-tags .panel-pane h2 a:focus {
  color: #0072b9;
}

body.section-tags .pane-node-links a {
  color: #D10000;
  text-decoration: none;
  text-transform: uppercase;
}

body.section-tags .pane-node-links a:hover, body.section-tags .pane-node-links a:active, body.section-tags .pane-node-links a:focus {
  color: #0072b9;
}

.hide-title h1 {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.hide-title .main-content {
  padding-top: 50px;
}

.no-banner-image section.banner {
  background: none !important;
}

.no-banner-image.node-type-landing-page section.banner {
  height: auto !important;
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:before,
.main-content:before,
.header:before,
.header .layout-center:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.main-content:after,
.header:after,
.header .layout-center:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.footer-wrapper {
  background: #555555 url("/sites/all/themes/boston_systems__solutions_theme/images-source/footer-bg.png") bottom center no-repeat;
  background-size: cover;
  padding: 5rem 0;
  color: #fff;
}

.footer-wrapper * {
  color: #fff;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside {
  margin: 0 -1rem;
}

@media all and (min-width: 768px) {
  .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-inside {
  padding: 0;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region {
  padding: 0 1rem;
  float: none;
  width: auto;
  text-transform: uppercase;
  text-align: center;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region {
    flex: 0 0 33.33%;
    text-align: left;
  }
}

@media all and (min-width: 992px) {
  .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region {
    flex: 0 0 25%;
    text-align: left;
  }
}

@media all and (min-width: 992px) {
  .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-first {
    text-align: right;
  }
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two .pane__title,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three .pane__title {
  display: inline-block;
  margin: .5rem 0;
  font-weight: 100;
  border-bottom: 1px solid #D10000;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two ul.menu,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three ul.menu {
  padding: 0;
  margin: 0;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two ul.menu li,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three ul.menu li {
  list-style-type: none;
  list-style-image: none;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two ul.menu a,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three ul.menu a {
  text-transform: none;
  text-decoration: none;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two ul.menu a:hover, .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two ul.menu a:active, .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-two ul.menu a:focus,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three ul.menu a:hover,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three ul.menu a:active,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-four_column_layout-three ul.menu a:focus {
  text-decoration: underline;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last {
    flex: 0 0 100%;
    text-align: left;
    text-align: center;
    border-top: 1px solid #D10000;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

@media all and (min-width: 992px) {
  .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last {
    text-align: center;
    border-left: 1px solid #D10000;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last h2 {
  margin: 0;
  font-size: 2rem;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last h2 a {
  text-decoration: none;
  font-weight: 100;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last p,
.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul {
  font-size: 0.75rem;
  line-height: 1rem;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last p {
  margin-bottom: 0;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu {
  padding: 0;
  margin: 0;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu li {
  display: inline;
  list-style-type: none;
  list-style-image: none;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu li:first-of-type a {
  border-right: 1px solid #fff;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu a {
  text-decoration: none;
  padding: 0 0.5rem;
  display: inline-block;
}

.panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu a:hover, .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu a:active, .panels-flexible-four_column_layout .panels-flexible-four_column_layout-inside .panels-flexible-region-last ul.menu a:focus {
  text-decoration: underline;
}

@media all and (min-width: 768px) {
  .admin-menu .header {
    top: 29px;
  }
}

.header {
  background: #fff;
  padding-bottom: 10px;
  width: 100%;
}

@media all and (min-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
}

.header .header__logo {
  padding-top: 10px;
  float: left;
  display: block;
  width: 200px;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .header .header__logo {
    padding-top: 44px;
    width: 200px;
  }
}

@media all and (min-width: 992px) {
  .header .header__logo {
    padding-top: 28px;
    width: 300px;
  }
}

.header .header__logo img {
  display: block;
  margin: 0;
  padding: 0;
}

.header .region-navigation .block-superfish {
  float: right;
}

.header .region-navigation .sf-accordion-toggle {
  padding-top: 10px;
  float: right;
}

.header .region-navigation .sf-accordion-toggle a#superfish-1-toggle {
  color: #D10000;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  border: 1px solid #D10000;
  padding: 5px;
  text-align: center;
}

.header .region-navigation .sf-accordion-toggle a#superfish-1-toggle.sf-expanded {
  top: -42px;
  position: relative;
}

.header .region-navigation .sf-menu.sf-accordion {
  top: -32px !important;
}

.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 {
  float: left;
  width: 50%;
}

.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 a,
.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 span {
  color: #D10000;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  background: none;
  text-align: center;
}

.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 a:hover, .header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 a:active, .header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 a.active,
.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 span:hover,
.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 span:active,
.header .region-navigation .sf-menu.sf-accordion li.sf-depth-1 span.active {
  background: #fff;
}

.header .region-navigation .sf-menu li.sf-depth-1 > a,
.header .region-navigation .sf-menu li.sf-depth-1 > span {
  color: #555555;
  text-decoration: none;
  text-transform: uppercase;
  padding: 60px 10px 10px 10px;
  background: none;
}

.header .region-navigation .sf-menu li.sf-depth-1 > a:hover, .header .region-navigation .sf-menu li.sf-depth-1 > a:active, .header .region-navigation .sf-menu li.sf-depth-1 > a.active,
.header .region-navigation .sf-menu li.sf-depth-1 > span:hover,
.header .region-navigation .sf-menu li.sf-depth-1 > span:active,
.header .region-navigation .sf-menu li.sf-depth-1 > span.active {
  color: #D10000;
}

.header .region-navigation .sf-menu li.sfHover > ul {
  top: 96px;
}

.header .region-navigation .sf-menu li.sfHover > ul a {
  color: #555555;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
}

.header .region-navigation .sf-menu li.sfHover > ul a:hover, .header .region-navigation .sf-menu li.sfHover > ul a:active, .header .region-navigation .sf-menu li.sfHover > ul a.active {
  color: #D10000;
}

section.banner {
  height: 100px;
  overflow: hidden;
}

@media (min-width: 768px) {
  section.banner {
    height: 300px;
  }
}

section.banner .flexslider {
  margin: 0;
  background: none;
  border: 0;
  border-radius: 0px;
  box-shadow: none;
}

section.banner .col-xs-4 {
  padding: 0 !important;
}

@media (min-width: 768px) {
  .not-front .main-wrapper {
    padding-top: 300px;
  }
}

.header__top {
  background: #D10000;
}

.header__top .block-search {
  padding-bottom: .5rem;
  display: flex;
}

.header__top .block-search i {
  padding-top: .5rem;
  padding-right: 1rem;
  color: #fff;
}

.header__top .block-search .form-item {
  margin: 0;
}

.header__top .block-search input[type="text"] {
  background: none;
  padding: .25rem 0;
  border-bottom: 1px solid #fff;
}

.header__top .block-search input[type="text"]:hover, .header__top .block-search input[type="text"]:active, .header__top .block-search input[type="text"]:focus {
  color: #fff;
  outline: none;
}

.header__top #block-block-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: .5rem;
  margin: 0 .5rem;
}

.header__top .header__top__phone {
  display: block;
  color: #D10000;
  text-decoration: none;
}

.header__top .header__top__phone:hover, .header__top .header__top__phone:active, .header__top .header__top__phone:focus {
  color: #0072b9;
}

.header__top #block-block-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__top .social-nav__link {
  padding: .25rem;
  text-decoration: none;
}

.header__top .social-nav__link:link, .header__top .social-nav__link:visited {
  color: #fff;
}

.header__top .social-nav__link:hover, .header__top .social-nav__link:active, .header__top .social-nav__link:focus {
  color: #0072b9;
}

.region-header-top {
  display: flex;
  justify-content: flex-end;
}

.sf-clone-parent {
  display: none;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #D10000;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff5f5;
  color: #D10000;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #D10000;
}

.messages--error path, .messages.error path {
  fill: #D10000;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

ol.search-results {
  padding: 0;
}

.search-result {
  border-bottom: 1px solid #000;
}

.search-result:last-of-type {
  border-bottom: none;
}

.visually-hidden, .header__top .block-search .form-submit,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.view-slides .flexslider {
  border: 0;
  margin: 0;
}

.view-slides .flexslider .slide-wrapper {
  max-height: 755px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.view-slides .flexslider video {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.view-slides .flexslider .field-slide-title {
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 2;
}

@media all and (min-width: 768px) {
  .view-slides .flexslider .field-slide-title {
    padding: 20px 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
  .view-slides .flexslider .field-slide-title:before, .view-slides .flexslider .field-slide-title:after {
    content: "";
    display: block;
    width: 25px;
    height: 100%;
    top: 0;
    z-index: 2;
    position: absolute;
  }
  .view-slides .flexslider .field-slide-title:before {
    left: -25px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+100 */
    background: -moz-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=1 );
    /* IE6-9 */
  }
  .view-slides .flexslider .field-slide-title:after {
    right: -25px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0+100 */
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#00000000',GradientType=1 );
    /* IE6-9 */
  }
}

.view-slides .flexslider .field-slide-title h4,
.view-slides .flexslider .field-slide-title h3 {
  margin: 0;
  text-transform: uppercase;
  line-height: 1em;
}

.view-slides .flexslider .field-slide-title h4 {
  font-weight: 100;
}

@media all and (min-width: 768px) {
  .view-slides .flexslider .field-slide-title h4 {
    font-size: 2em;
  }
}

@media all and (min-width: 992px) {
  .view-slides .flexslider .field-slide-title h4 {
    font-size: 3em;
  }
}

@media all and (min-width: 1200px) {
  .view-slides .flexslider .field-slide-title h4 {
    font-size: 4em;
  }
}

@media all and (min-width: 768px) {
  .view-slides .flexslider .field-slide-title h3 {
    margin-top: 10px;
    font-size: 3em;
  }
}

@media all and (min-width: 992px) {
  .view-slides .flexslider .field-slide-title h3 {
    margin-top: 20px;
    font-size: 4em;
  }
}

@media all and (min-width: 1200px) {
  .view-slides .flexslider .field-slide-title h3 {
    margin-top: 20px;
    font-size: 5em;
  }
}

.view-slides .flexslider .slide-caption-wrapper {
  background: #D10000;
  color: #fff;
  z-index: 3;
}

@media all and (min-width: 992px) {
  .view-slides .flexslider .slide-caption-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.view-slides .flexslider .flex-control-nav {
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 2;
  bottom: 0px;
}

@media all and (min-width: 768px) {
  .view-slides .flexslider .flex-control-nav {
    text-align: right;
    bottom: 20%;
    right: 30px;
  }
}

.view-slides .flexslider .flex-control-nav a {
  text-align: left;
  background: transparent;
  border: 2px solid #000;
  width: 20px;
  height: 20px;
  text-shadow: none;
  box-shadow: none;
}

@media all and (min-width: 768px) {
  .view-slides .flexslider .flex-control-nav a {
    border: 2px solid #fff;
  }
}

.view-slides .flexslider .flex-control-nav a.flex-active, .view-slides .flexslider .flex-control-nav a:hover, .view-slides .flexslider .flex-control-nav a:active {
  background: #000;
}

@media all and (min-width: 768px) {
  .view-slides .flexslider .flex-control-nav a.flex-active, .view-slides .flexslider .flex-control-nav a:hover, .view-slides .flexslider .flex-control-nav a:active {
    background: #fff;
  }
}

.video-wrapper {
  position: relative;
  z-index: 1;
}

.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.jq-ui-override.services-tabs {
  font-family: "Alegreya Sans", sans-serif;
}

.jq-ui-override.services-tabs .view-header {
  border-bottom: 10px solid #D10000;
}

.jq-ui-override.services-tabs .ui-tabs-panel {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.jq-ui-override.services-tabs .ui-tabs-panel h3 {
  background: #D10000;
  margin: 0 auto;
  padding: 1rem;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 1em;
}

.jq-ui-override.services-tabs .ui-tabs-panel * {
  text-align: left;
}

.jq-ui-override.services-tabs.ui-tabs {
  padding: 0;
  border: 0;
}

.jq-ui-override.services-tabs.ui-corner-all {
  border-radius: 0;
}

.jq-ui-override.services-tabs .ui-corner-all {
  border-radius: 0;
}

.jq-ui-override.services-tabs .ui-widget-header {
  background: none;
  border: 0;
}

.jq-ui-override.services-tabs .ui-tabs-nav {
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
}

.jq-ui-override.services-tabs .ui-tabs-nav li {
  margin: 0;
  background: none;
  border: 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-bottom: 30px;
}

.jq-ui-override.services-tabs .ui-tabs-nav li.ui-tabs-active {
  padding-bottom: 0;
}

.jq-ui-override.services-tabs .ui-tabs-nav li.ui-tabs-active a:before {
  display: block;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #D10000;
  background: rgba(209, 0, 0, 0.75);
  position: absolute;
}

.jq-ui-override.services-tabs .ui-tabs-nav li a {
  float: none;
  display: block;
  position: relative;
  z-index: 1;
  padding: 0;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  white-space: normal;
}

.jq-ui-override.services-tabs .ui-tabs-nav li a img {
  display: block;
  width: 100%;
  height: auto;
}

.jq-ui-override.services-tabs .ui-tabs-nav li a p {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 5px;
  width: 100%;
  text-align: center;
}

.field-icon-grid {
  color: #D10000;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.field-icon-grid img {
  width: auto;
  height: 124px;
}

.field-icon-grid a {
  color: #D10000;
  display: block;
  text-decoration: none;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 10px;
}

.field-icon-grid a:hover, .field-icon-grid a:active {
  border: 1px solid #c5c5c5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
}

.paragraphs-item-image-with-text .image_with_text_wrapper {
  position: relative;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  display: inline-block;
}

.paragraphs-item-image-with-text .image_with_text_wrapper img {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

.paragraphs-item-image-with-text .image_with_text_wrapper .field-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  padding: 10px;
  line-height: 1em;
}

.paragraphs-item-leadership {
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #EBEBEB;
  position: relative;
}

.paragraphs-item-leadership:before, .paragraphs-item-leadership:after {
  content: "";
  display: block;
  width: 9999px;
  height: 100%;
  position: absolute;
  top: 0;
  background: #EBEBEB;
}

.paragraphs-item-leadership:before {
  right: -9999px;
}

.paragraphs-item-leadership:after {
  left: -9999px;
}

.field-leadership {
  margin-bottom: 30px;
}

.field-leadership h3 {
  display: none;
}

.field-leadership img {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.field-leadership:hover .leadership_wrapper .leadership_meta_wrapper, .field-leadership:active .leadership_wrapper .leadership_meta_wrapper {
  transition: all .25s ease-in;
}

@media all and (min-width: 768px) {
  .field-leadership:hover .leadership_wrapper .leadership_meta_wrapper, .field-leadership:active .leadership_wrapper .leadership_meta_wrapper {
    transform: translateY(0);
  }
}

.field-leadership .leadership_wrapper {
  position: relative;
  z-index: 1;
}

@media all and (min-width: 768px) {
  .field-leadership .leadership_wrapper {
    overflow: hidden;
  }
}

.field-leadership .leadership_wrapper .leadership_meta_wrapper {
  background: #D10000;
  background: rgba(209, 0, 0, 0.75);
}

@media all and (min-width: 768px) {
  .field-leadership .leadership_wrapper .leadership_meta_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all .25s ease-in;
    transform: translateY(100%);
  }
}

.field-leadership .leadership_wrapper .leadership_meta_wrapper .leadership_meta_container {
  text-align: center;
  color: #fff;
}

@media all and (min-width: 768px) {
  .field-leadership .leadership_wrapper .leadership_meta_wrapper .leadership_meta_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 5px;
  }
}

.field-leadership .leadership_wrapper .leadership_meta_wrapper .field-leadership-name {
  font-size: 2em;
  line-height: 1.25em;
}

.field-leadership .leadership_wrapper .leadership_meta_wrapper .field-leadership-name,
.field-leadership .leadership_wrapper .leadership_meta_wrapper .field-leadership-title {
  text-transform: uppercase;
}

.edge-to-edge-banner {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
}

@media all and (min-width: 768px) {
  .edge-to-edge-banner {
    padding-top: 137px;
    /* offset header */
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}

.edge-to-edge-banner .pane-paragraphs-item-field-image {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media all and (min-width: 768px) {
  .edge-to-edge-banner .pane-paragraphs-item-field-description {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

@media all and (min-width: 768px) {
  .edge-to-edge-banner .pane-paragraphs-item-field-description,
  .edge-to-edge-banner .pane-paragraphs-item-field-image {
    flex: 0 0 50%;
  }
}

.edge-to-edge-banner .pane-paragraphs-item-field-description {
  display: flex;
  align-items: center;
}

.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem;
  color: #fff;
}

@media all and (min-width: 992px) {
  .edge-to-edge-banner .pane-paragraphs-item-field-description .field-description {
    padding-left: 10rem;
  }
}

.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description a {
  color: #D10000;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: .25rem;
  text-decoration: none;
}

.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description a:hover, .edge-to-edge-banner .pane-paragraphs-item-field-description .field-description a:active, .edge-to-edge-banner .pane-paragraphs-item-field-description .field-description a:focus {
  color: #0072b9;
}

.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description h2,
.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description h3 {
  margin: 0;
  text-transform: uppercase;
}

.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description h2 {
  font-size: 4rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}

.edge-to-edge-banner .pane-paragraphs-item-field-description .field-description h3 {
  font-weight: 100;
  letter-spacing: 1rem;
  font-size: 2rem;
  line-height: 4rem;
}

.edge-to-edge-banner .pane-paragraphs-item-field-image {
  text-align: center;
}

@media all and (min-width: 768px) {
  .edge-to-edge-banner .pane-paragraphs-item-field-image {
    text-align: right;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.edge-to-edge-banner .pane-paragraphs-item-field-image a {
  display: block;
  text-decoration: none;
}

.edge-to-edge-banner .pane-paragraphs-item-field-image a:hover, .edge-to-edge-banner .pane-paragraphs-item-field-image a:active, .edge-to-edge-banner .pane-paragraphs-item-field-image a:focus {
  text-decoration: none;
}

.paragraphs-item-highlights {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media all and (min-width: 768px) {
  .paragraphs-item-highlights > .content {
    display: flex;
    justify-content: space-between;
  }
}

.paragraphs-item-highlights > .content .field-highlights {
  position: relative;
  z-index: 1;
  border: 1px solid #EBEBEB;
}

@media all and (min-width: 768px) {
  .paragraphs-item-highlights > .content .field-highlights {
    flex: 0 0 33.33%;
  }
}

.paragraphs-item-highlights .collapsible.highlight > h3 {
  display: none;
}

.paragraphs-item-highlights .field-highlight-image {
  border-top: 2rem solid #D10000;
}

.paragraphs-item-highlights .field-highlight-image img {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.paragraphs-item-highlights .highlight__content {
  padding: 1rem;
  text-align: center;
  position: relative;
}

@media all and (min-width: 992px) {
  .paragraphs-item-highlights .highlight__content {
    background: #fff;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
  }
}

.paragraphs-item-highlights .highlight__content:before {
  content: "";
  display: block;
  position: absolute;
  top: -31px;
  left: 50%;
  margin-left: -20px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #D10000;
  border-left: 20px solid transparent;
}

.paragraphs-item-highlights .highlight__title {
  color: #D10000;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 1rem;
}

@media all and (min-width: 1200px) {
  .paragraphs-item-highlights .highlight__title {
    font-size: 1.5rem;
  }
}

.paragraphs-item-highlights .highlight__link {
  margin: 2rem auto;
}

.paragraphs-item-highlights .highlight__link a {
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
}

.paragraphs-item-highlights .highlight__link a:hover, .paragraphs-item-highlights .highlight__link a:active, .paragraphs-item-highlights .highlight__link a:focus {
  color: #0072b9;
}

.highlights {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.highlights > .view-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.highlights > .view-content .highlight {
  position: relative;
  z-index: 1;
  border: 1px solid #EBEBEB;
  flex: 0 0 50%;
}

@media all and (min-width: 768px) {
  .highlights > .view-content .highlight {
    flex: 0 0 33.33%;
  }
}

.highlights > .view-content .highlight__container {
  text-decoration: none;
  color: #555555;
}

.highlights > .view-content .highlight__container:hover .highlight__content, .highlights > .view-content .highlight__container:active .highlight__content, .highlights > .view-content .highlight__container:focus .highlight__content {
  background: #0072b9;
  background: rgba(0, 114, 185, 0.8);
  color: #fff;
}

.highlights .field-services-image {
  border-top: 2rem solid #D10000;
}

.highlights .field-services-image img {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.highlights .highlight__content {
  padding: 1rem;
  text-align: center;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlights .highlight__content:before {
  content: "";
  display: block;
  position: absolute;
  top: -31px;
  left: 50%;
  margin-left: -20px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #D10000;
  border-left: 20px solid transparent;
}

.highlights .highlight__title {
  color: #D10000;
  text-transform: uppercase;
  margin-bottom: 0rem;
  font-weight: bold;
  font-size: 1rem;
}

@media all and (min-width: 768px) {
  .highlights .highlight__title {
    font-size: 1.5rem;
  }
}

.highlights .highlight__link {
  margin: .5rem auto;
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
}

.edge-to-edge-image {
  margin-top: 2rem;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  color: #fff;
  padding: 5rem 1rem;
  z-index: 1;
  text-align: center;
}

.edge-to-edge-image .pane-paragraphs-item-field-banner-image {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.edge-to-edge-image .pane-paragraphs-item-field-long-text {
  position: relative;
  z-index: 10;
}

.edge-to-edge-image .field-long-text * {
  font-weight: 100;
}

@media all and (min-width: 768px) {
  .edge-to-edge-image .field-long-text h2 {
    font-size: 3rem;
  }
}

@media all and (min-width: 768px) {
  .edge-to-edge-image .field-long-text h3 {
    font-size: 2rem;
  }
}

.edge-to-edge-image .field-long-text p {
  font-size: 1.5rem;
}

.edge-to-edge-image .field-long-text a {
  color: #D10000;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.25rem;
}

.edge-to-edge-image .field-long-text a:hover, .edge-to-edge-image .field-long-text a:active, .edge-to-edge-image .field-long-text a:focus {
  color: #0072b9;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #D10000;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #D10000;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
