.paragraphs-item-highlights {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    > .content {
        @media all and (min-width: $col-sm) {
            display: flex;
            justify-content: space-between;      
        }
        .field-highlights {
            position: relative;
            z-index: 1;
            border: 1px solid color('custom-gray-1');
            @media all and (min-width: $col-sm) {
                flex: 0 0 33.33%;
            }
        }
    }

    // hide toggle
    .collapsible.highlight {
        > h3 {
            display: none;
        }
    }

    // image
    .field-highlight-image {
        border-top: 2rem solid color('red');

        img {
            display: block;
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
        }
    }

    // content
    .highlight__content {
        padding: 1rem;
        text-align: center;
        position: relative;

        @media all and (min-width: $col-md) {
            background: color('white');
            background: rgba(color('white'), 0.8);
            position: absolute;
            bottom: 2rem;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 2;
        }

        // triangle
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -31px;
            left: 50%;
            margin-left: -20px;
            height: 0;
            width: 0;
            border-top: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid color('red');
            border-left: 20px solid transparent;
        }
    }
    .highlight__title {
        color: color('red');
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: bold;
        font-size: 1rem;

        @media all and (min-width: $col-lg) {
            font-size: 1.5rem;
        }
    }
    .highlight__link {
        margin: 2rem auto;
        a {
            color: color('red');
            text-transform: uppercase;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                color: color('blue');
            }
        }
    }
}

// views reference
.highlights {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    
    > .view-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .highlight {
            position: relative;
            z-index: 1;
            border: 1px solid color('custom-gray-1');
            flex: 0 0 50%;
            @media all and (min-width: $col-sm) {
                flex: 0 0 33.33%;
            }          
        }

        .highlight__container {
            text-decoration: none;
            color: color('custom-gray-2');
    
            &:hover,
            &:active,
            &:focus {
                .highlight__content {
                    background: color('blue');
                    background: rgba(color('blue'), 0.8);
                    color: color('white');
                }
            }            
        }
    }

        // image
        .field-services-image{
            border-top: 2rem solid color('red');
    
            img {
                display: block;
                width: 100%;
                height: auto;
                padding: 0;
                margin: 0;
            }
        }

    // content
    .highlight__content {
        padding: 1rem;
        text-align: center;
        background: color('white');
        background: rgba(color('white'), 0.8);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // triangle
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -31px;
            left: 50%;
            margin-left: -20px;
            height: 0;
            width: 0;
            border-top: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid color('red');
            border-left: 20px solid transparent;
        }
    }
    .highlight__title {
        color: color('red');
        text-transform: uppercase;
        margin-bottom: 0rem;
        font-weight: bold;
        font-size: 1rem;
        @media all and (min-width: $col-sm) {
            font-size: 1.5rem;
        }        
    }
    .highlight__link {
        margin: .5rem auto;
        color: color('red');
        text-transform: uppercase;
        text-decoration: none;
    }        
}
