// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

// logged in
.admin-menu {
  .header,
  %header {
    @media all and (min-width: $col-sm) {
      top: 29px;
    }
  }
}

.header,
%header {
  @extend %clearfix;
  background: color('white');
  padding-bottom: 10px;
  width: 100%;

  @media all and (min-width: $col-sm) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }

  
  // clear wrapper
  .layout-center {
    @extend %clearfix;
  }

  // flex layout
  // > .container {

  //   &:before,
  //   &:after {
  //     display: none;
  //   }

  //   display: flex;
  //   justify-content: space-between;
  // }

  // logo
  .header__logo {
    padding-top: 10px;
    float: left;
    display: block;
    width: 200px;

    @media all and (min-width: $col-sm) and (max-width: $col-md - 1px) {
      padding-top: 44px;
      width: 200px;
    }

    @media all and (min-width: $col-md) {
      padding-top: 28px;
      width: 300px;
    }

    img {
      display: block;
      margin: 0;
      padding: 0;
    }

  }

  // navigation
  .region-navigation {

    // float nav items to the right
    .block-superfish {
      float: right;
    }

    // mobile
    .sf-accordion-toggle {
      padding-top: 10px;
      float: right;

      // button
      a#superfish-1-toggle {
        color: color('red');
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        border: 1px solid color('red');
        padding: 5px;
        text-align: center;
        // force mobile menu to not clear logo on toggle
        &.sf-expanded {
          top: -42px;
          position: relative;
        }
      }
    }
    // items
    .sf-menu.sf-accordion {
      // bring menu close to toggle button
      top: -32px !important;

      li.sf-depth-1 {
        float: left;
        width: 50%;
        // default style
        a,
        span {
          color: color('red');
          text-decoration: none;
          text-transform: uppercase;
          padding: 10px;
          background: none;
          text-align: center;

          // hover and active
          &:hover,
          &:active,
          &.active {
            background: color('white');
          }

        }
      }
    }

    // desktop
    .sf-menu {
      // level 1
      li.sf-depth-1 {
        // default style
        > a,
        > span {
          color: color('custom-gray-2');
          text-decoration: none;
          text-transform: uppercase;
          padding: 60px 10px 10px 10px;
          background: none; 

          // hover and active
          &:hover,
          &:active,
          &.active {
            color: color('red');
          }

        }
      }
      // level 2
      li.sfHover > ul {
        top: 96px;
        a {
          color: color('custom-gray-2');
          text-decoration: none;
          text-transform: uppercase;
          padding: 10px;
          background: color('white');
          background: rgba(color('white'), 0.8);

          // hover and active
          &:hover,
          &:active,
          &.active {
            color: color('red');
          }

        }        
      }
    }

  }
}

// banner and rotation blocks
section.banner {
  height: 100px;
  overflow: hidden;

  @include respond-to(col-sm) {
    height: 300px;
  }

  // override flexslider default styles
  .flexslider {
    margin: 0;
    background: none;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
  }

  // remove padding from columns
  .col-xs-4 {
    padding: 0 !important;
  }

}

.not-front {
  .main-wrapper {
    // padding-top: 100px;
  
    @include respond-to(col-sm) {
      padding-top: 300px;
    }
  }
}


// header top
.header__top {
  background: color('red');

  // search
  .block-search {
    padding-bottom: .5rem;
    display: flex;

    i {
      padding-top: .5rem;
      padding-right: 1rem;
      color: color("white");
    }

    .form-item {
      margin: 0;
    }
    // input
    input[type="text"] {
      background: none;
      padding: .25rem 0;
      border-bottom: 1px solid color('white');

      &:hover,
      &:active,
      &:focus {
        // background: color('white');
        color: color('white');
        outline: none;
      }
    }
    // hide submit
    .form-submit {
      @extend .visually-hidden;
    }
  }

  // phone
  #block-block-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: color('white');
    padding: .5rem;
    margin: 0 .5rem;
  }
  .header__top__phone {
    display: block;
    color: color('red');
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: color('blue');
    }
  }

  // social
  #block-block-2 {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .social-nav__link {
    padding: .25rem;
    text-decoration: none;
    &:link,
    &:visited {
      color: color('white');
    }
    &:hover,
    &:active,
    &:focus {
      color: color('blue');
    }    
  }

}
.region-header-top {
  display: flex;
  justify-content: flex-end;
}

// hide duplicate nolink nav item on mobile menu
.sf-clone-parent {
  display: none;
}