.field-icon-grid {
  color: color('red');
  text-transform: uppercase;
  text-align: center;
  margin-bottom: $zen-gutters;

  // image
  img {
    width: auto;
    height: 124px;
  }

  // link
  a {
    color: color('red');
    display: block;
    text-decoration: none;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 10px;


    &:hover,
    &:active {
      border: 1px solid darken(color('custom-gray-1'), 15%);
      box-shadow: 0px 2px 2px rgba(black, 0.35);
    }
  }
}
