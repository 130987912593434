// latest posts
.view-latest-blog-posts {
    // layout
    .view-content {
        margin: 0 -1rem;
        @media all and (min-width: $col-sm) {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        .views-row {
            padding: 0 1rem;
            @media all and (min-width: $col-sm) and (max-width: $col-md - 1px) {
                flex: 0 0 50%;
            }
            @media all and (min-width: $col-md) {
                flex: 0 0 25%;
            }
        }
    }
    // footer
    .view-footer {
        text-align: center;
        h3 {
            text-align: center;
            font-weight: 100;
            font-size: 1.5rem;

            a {
                color: color('red');
                text-transform: uppercase;
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }
        }
    }
    // header
    .view-header {
        h2 {
            text-align: center;
            font-weight: 100;
            color: color('red');
            font-size: 2rem;
        }
    }
    // content
    .view-content {
        // image
        .field-image {
            overflow: hidden;

            a {
                display: block;
                transform: scale(1,1);
                transition: all 0.25s ease-in-out;

                &:hover,
                &:active,
                &:focus {
                    transform: scale(1.5,1.5);
                    transition: all 0.25s ease-in-out;
                }
            }
        } 
        // titles
        h2 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            a {
                color: color('red');
                text-transform: uppercase;
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }
        }
    }

    // content
    .node-latest-blog-posts {
        text-align: center;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media all and (min-width: $col-sm) {
            text-align: left;
        }

        // place readmore link at bottom
        .pane-node-links {
            margin-top:auto;

            a {
                color: color('red');
                text-decoration: none;
                text-transform: uppercase;

                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }
        }
    }

}

// index
.view-blog {
    // content
    .view-content {
        // title
        h2 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            a {
                color: color('red');
                text-transform: uppercase;
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }
        }
        // readmore
        .pane-node-links {
            a {
                color: color('red');
                text-decoration: none;
                text-transform: uppercase;

                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }
        }
    }  
}

// tags
body.section-tags {
    // title
    .panel-pane {
        h2 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            a {
                color: color('red');
                text-transform: uppercase;
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }
        }
    }
    // readmore
    .pane-node-links {
        a {
            color: color('red');
            text-decoration: none;
            text-transform: uppercase;

            &:hover,
            &:active,
            &:focus {
                color: color('blue');
            }
        }
    }    
}