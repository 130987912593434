// override default panels styles
.row .panels-flexible-region {
  padding-left: 15px;
  padding-right: 15px;
}

// add padding to content
.main-content {
  @extend %clearfix;
  padding-bottom: 25px;
}
