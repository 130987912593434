// override defaults
.jq-ui-override.services-tabs {
  @include typeface(body);

  // red border
  .view-header {
    border-bottom: 10px solid color('red');
  }

  // content
  .ui-tabs-panel {
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    h3 {
      background: color('red');
      margin: 0 auto;
      padding: 1rem;
      display: inline-block;
      color: color('white');
      text-transform: uppercase;
      font-weight: 100;
      line-height: 1em;
    }

    * {
      text-align: left;
    }
  }

  &.ui-tabs {
    padding: 0;
    border: 0;
  }
  // remove border radius
  &.ui-corner-all {
    border-radius: 0;
  }
  .ui-corner-all {
    border-radius: 0;
  }

  // header
  .ui-widget-header {
    background: none;
    border: 0;
  }

  // navigation
  .ui-tabs-nav {
    padding: 0;
    margin-left: -($zen-gutters/2);
    margin-right: -($zen-gutters/2);

    // navigation elements
    li {
      margin: 0;
      background: none;
      border: 0;
      padding-left: $zen-gutters/2 !important;
      padding-right: $zen-gutters/2 !important;
      margin-bottom: $zen-gutters;

      // active state
      &.ui-tabs-active {
        padding-bottom: 0;

        a {
          &:before {
            display: block;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: color('red');
            background: rgba(color('red'),0.75);
            position: absolute;
          }
        }
      }

      // image and text
      a {
        float: none;
        display: block;
        position: relative;
        z-index: 1;
        padding: 0;
        color: color('white');
        text-transform: uppercase;
        text-shadow: 2px 2px 2px rgba(black, 0.8);
        white-space: normal;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        // place text over image
        p {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%,-50%);
          margin: 0;
          padding: 5px;
          width: 100%;
          text-align: center;
        }

      }
    }
  }
}
