.edge-to-edge-banner {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;

    @media all and (min-width: $col-sm) {
        padding-top: 137px; /* offset header */
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .pane-paragraphs-item-field-image {
        margin-top: 5rem;
        margin-bottom: 5rem;        
    }
    .pane-paragraphs-item-field-description {
        @media all and (min-width: $col-sm) {
            margin-top: 5rem;
            margin-bottom: 5rem;
        }
    }
    .pane-paragraphs-item-field-description,
    .pane-paragraphs-item-field-image {
        @media all and (min-width: $col-sm) {
            flex: 0 0 50%;
        }
    }

    // description
    .pane-paragraphs-item-field-description {
        display: flex;
        align-items: center;

        .field-description {
            background: color('black');
            background: rgba(color('black'), 0.9);
            padding: 1rem;
            @media all and (min-width: $col-md) {
                padding-left: 10rem;
            }

            // link
            a {
                color: color('red');
                font-size: 1.5rem;
                text-transform: uppercase;
                letter-spacing: .25rem;
                text-decoration: none;

                &:hover,
                &:active,
                &:focus {
                    color: color('blue');
                }
            }

            // content
            color: color('white');
            h2,
            h3 {
                margin: 0;
                text-transform: uppercase;
            }
            h2 {
                font-size: 4rem;
                line-height: 2rem;
                margin-bottom: 2rem;
            }
            h3 {
                font-weight: 100;
                letter-spacing: 1rem;
                font-size: 2rem;
                line-height: 4rem;  
            }

        }
    }

    // image
    .pane-paragraphs-item-field-image {
        text-align: center;
        @media all and (min-width: $col-sm) {
            text-align: right;
            padding-right: 1rem;
            padding-left: 1rem;
        }

        a {
            display: block;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
}



