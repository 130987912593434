.paragraphs-item-image-with-text {

  .image_with_text_wrapper {
    position: relative;
    z-index: 1;
    color: color('white');
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(black, 0.8);

    // make wrapper as wide as its content
    display: inline-block;

    // make image fill space
    img {
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
    }

    // center text over image
    .field-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 2;
      font-size: 1.5em;
      width: 100%;
      text-align: center;
      padding: 10px;
      line-height: 1em;
    }
  }

}
