// target slide flexslider
.view-slides {

  // override flexslider defaults
  .flexslider {
    border: 0;
    margin: 0;

    // set max height on image and video
    .slide-wrapper {
      max-height: 755px;
      overflow: hidden;
      // set to relative so title can be placed over slides
      position: relative;
      z-index: 1;
    }

    // make video full width
    video {
      object-fit: fill;
      height: 100%;
      width: 100%;
    }

    // slide title
    .field-slide-title {
      text-align: center;
      color: color('white');
      background: rgba( color('black'), 0.8 );
      padding: 20px;
      position: absolute;
      // offset header
      top: 56px;
      width: 100%;
      z-index: 2;

      @media all and (min-width: $col-sm) {
        padding: 20px 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;

        // gradient
        &:before,
        &:after {
          content: "";
          display: block;
          width: 25px;
          height: 100%;
          top: 0;
          z-index: 2;
          position: absolute;
        }
        &:before {
          left: -25px;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+100 */
          background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=1 ); /* IE6-9 */
        }
        &:after {
          right: -25px;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0+100 */
          background: -moz-linear-gradient(left, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        }

      }

      // tighten text
      h4,
      h3 {
        margin: 0;
        text-transform: uppercase;
        line-height: 1em;
      }

      // adjust size
      h4 {
        font-weight: 100;
        @media all and (min-width: $col-sm) {
          font-size: 2em;
        }
        @media all and (min-width: $col-md) {
          font-size: 3em;
        }
        @media all and (min-width: $col-lg) {
          font-size: 4em;
        }
      }
      h3 {
        @media all and (min-width: $col-sm) {
          margin-top: 10px;
          font-size: 3em;
        }
        @media all and (min-width: $col-md) {
          margin-top: 20px;
          font-size: 4em;
        }
        @media all and (min-width: $col-lg) {
          margin-top: 20px;
          font-size: 5em;
        }
      }

    }

    // slide caption
    .slide-caption-wrapper {
      // place caption over the image/video
      background: color('red');
      color: color('white');
      z-index: 3;
      @media all and (min-width: $col-md) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
      }
    }

    // controls
    .flex-control-nav {
      width: 100%;
      position: absolute;
      text-align: center;
      z-index: 2;
      bottom: 0px;

      @media all and (min-width: $col-sm) {
        text-align: right;
        bottom: 20%;
        right: 30px;
      }

      a {
        // align text to left so it is hidden
        text-align: left;
        // override defaults
        background: transparent;
        border: 2px solid color('black');
        width: 20px;
        height: 20px;
        text-shadow: none;
        box-shadow: none;

        @media all and (min-width: $col-sm) {
          border: 2px solid color('white');
        }

        // active button
        &.flex-active,
        &:hover,
        &:active {
          background: color('black');
          @media all and (min-width: $col-sm) {
            background: color('white');
          }
        }
      }


    }

  }
}

// still image fallback
.video-wrapper {
  position: relative;
  z-index: 1;

  // place video over fallback image
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}
