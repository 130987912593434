// wrapper
.paragraphs-item-leadership {
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: color('custom-gray-1');

  // edge to edge
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    width: 9999px;
    height: 100%;
    position: absolute;
    top: 0;
    background: color('custom-gray-1');
  }
  &:before {
    right: -9999px
  }
  &:after {
    left: -9999px
  }
}


// individual fields
.field-leadership {
  margin-bottom: $zen-gutters;

  // make sure field group labels are hidden
  h3 {
    display: none;
  }

  // make sure image fills out space
  img {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  &:hover,
  &:active {
    .leadership_wrapper {
      .leadership_meta_wrapper {
        transition: all .25s ease-in;

        @media all and (min-width: $col-sm) {
          transform: translateY(0);
        }
      }
    }
  }

  // hover styles
  .leadership_wrapper {
    position: relative;
    @media all and (min-width: $col-sm) {
      overflow: hidden;
    }
    z-index: 1;

    // meta info
    .leadership_meta_wrapper {
      background: color('red');
      background: rgba(color('red'),0.75);

      @media all and (min-width: $col-sm) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all .25s ease-in;
        transform: translateY(100%);
      }

      // center text
      .leadership_meta_container {
        text-align: center;
        color: color('white');
        @media all and (min-width: $col-sm) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
          padding: 0 5px;
        }
      }

      // name and title
      .field-leadership-name {
        font-size: 2em;
        line-height: 1.25em;
      }
      .field-leadership-name,
      .field-leadership-title {
        text-transform: uppercase;
      }

    }

  }

}
