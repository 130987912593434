// hide page title
.hide-title {
  h1 {
    @include visually-hidden;
  }
  // add some padding since the title is gone
  .main-content {
    padding-top: 50px;
  }
}

// interior banners
.no-banner-image {
  // remove background image
  section.banner{
    background: none !important;
  }

  // set height to auto for landing pages
  &.node-type-landing-page {
    section.banner{
      height: auto !important;
    }
  }
}
