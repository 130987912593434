/*********************/
/*** PANELS LAYOUT ***/
/*********************/
.panels-flexible-landing_page {
    background: color('white');

    // add container class
    .panels-flexible-row-first,
    .panels-flexible-row-landing_page-2 {
        > div.inside {
            @extend .container;
            // add row class
            > div.panels-flexible-region {
                @extend .row;
                // make full width
                > div.inside {
                    @extend .col-xs-12;
                    @extend .col-sm-12;
                    @extend .col-md-12;
                    @extend .col-lg-12;  
                }
            }
        }
    }
    // two columns
    .panels-flexible-row-landing_page-main-row {
        @extend .container;
        > div.inside {
            @extend .row;
            > div.panels-flexible-region {
                @extend .col-xs-12;
                @extend .col-sm-6;
                @extend .col-md-6;
                @extend .col-lg-6;
            }
        }  
    }
}

/********************/
/****** HEADER ******/
/********************/
.panels-flexible-landing_page {
    // background image
    .panels-flexible-row-first {
        margin-bottom: 50px;
        background: color('black') url('../images-source/landing_page_mobile_bg.jpg') center top no-repeat;
        // background-size: cover;

        // align title with red line
        padding-top: 65px;
        min-height: 900px;

        @media all and (min-width: $col-sm) and (max-width: $col-md){
            background: color('black') url('../images-source/landing_page_bg.jpg') center top no-repeat;
            padding-top: 160px;
            height: 900px;
        }
        @media all and (min-width: $col-md) and (max-width: $col-lg){
            background: color('black') url('../images-source/landing_page_bg.jpg') center top no-repeat;
            padding-top: 150px;
            height: 900px;
        }
        @media all and (min-width: $col-lg) {
            background: color('black') url('../images-source/landing_page_bg.jpg') center top no-repeat;
            background-size: cover;
            padding-top: 180px;
            height: 800px;
        }

    }
    // logo
    .pane-page-logo {
        a {
            display: block;
            text-align: center;
        }
        @media all and (min-width: $col-sm) and (max-width: $col-md){
            margin-top: -15px;
        }
        @media all and (min-width: $col-md) and (max-width: $col-lg){
            margin-top: -15px;
        }

        @media all and (min-width: $col-sm) and (max-width: $col-md){
            float: left;
            width: 200px;
            height: auto;
        }
        @media all and (min-width: $col-md) and (max-width: $col-lg){
            float: left;
            width: 300px;
            height: auto;
        }
        @media all and (min-width: $col-lg) {
            float: left;
            width: 380px;
            height: auto;
        }

    }
    // title
    .custom-page-title {
        text-align: center;

        @media all and (min-width: $col-sm) and (max-width: $col-md){
            margin-left: 100px;
            text-align: right;
        }
        @media all and (min-width: $col-md) and (max-width: $col-lg){
            margin-left: 300px;
            text-align: right;
        }
        @media all and (min-width: $col-lg) {
            margin-left: 450px;
            text-align: right;
        }        

        p {
            color: color("maroon");
            font-size: 1em;
            line-height: 1.2em;

            &:first-of-type {
                display: block;
                @media all and (min-width: $col-sm) {
                    display: none;
                }
            }
            &:last-of-type {
                display: none;
                @media all and (min-width: $col-sm) {
                    display: block;
                }
            }            

            @media all and (min-width: $col-sm) {
                font-size: 1.5em;
                line-height: 1.4em;
            }
        }

        a {
            color: color("maroon");

            &:hover,
            &:active {
                color: color("blue");
            }
        }

        h1 {
            font-weight: 100;
            font-family: $arial;
            color: color('maroon');
            span.black {
                color: color('black');
            }
            
            // align title with red line
            font-size: 4em;
            line-height: 1em;
            margin-top: .5em;
            margin-bottom: .5em;

            @media all and (min-width: $col-sm) and (max-width: $col-md){
                margin-top: 0;
                margin-bottom: 0;
                font-size: 5em;
                line-height: 1em;
            }
            @media all and (min-width: $col-md) and (max-width: $col-lg){
                margin-top: 0;
                margin-bottom: 0;
                font-size: 6em;
                line-height: 1em;
            }
            @media all and (min-width: $col-lg) {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 9em;
                line-height: 1em;
            }

        }

    }

    // subhead-one, subhead-two
    .subhead-one,
    .subhead-two {
        text-align: center;
        color: color("white");
        text-shadow: 2px 2px 2px rgba(0,0,0,0.75);

        // @media all and (min-width: $col-sm) {
        //     color: color("white");
        //     text-shadow: 2px 2px 2px rgba(0,0,0,0.75);
        // }

        h2 {
            font-size: 1.25em;
            line-height: 1.25em;
            font-weight: 100;

            @media all and (min-width: $col-sm) and (max-width: $col-md){
                font-size: 2.7em;
                line-height: 1.25em;
            }
            @media all and (min-width: $col-md) and (max-width: $col-lg){
                font-size: 2.7em;
                line-height: 1.25em;
            }
            @media all and (min-width: $col-lg) {
                font-size: 2.7em;
                line-height: 1.25em;
            }            

            strong {
                font-weight: bold;
            }

            em {
                text-transform: uppercase;
            }
        }
    }

    // subhead-one
    .subhead-one {
        clear: both;
        margin-top: 140px;
        margin-bottom: 50px;
    }

    // subhead-two
    .subhead-two {
        h2 {
            text-transform: uppercase;
        }
    }

}

/*******************/
/****** PHONE ******/
/*******************/
.panels-flexible-landing_page {
    .panels-flexible-row-landing_page-main-row {
        > div.inside {
            > div.panels-flexible-region-first{
                text-align: center;
                color: color("maroon");
                text-transform: uppercase;

                a {
                    color: color("maroon");

                    &:link,
                    &:visited {
                        color: color("maroon");
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: color('blue');
                    }
                }
                
                p {
                    font-size: 1.5em;
                    line-height: 1em;
                    letter-spacing: .3em;
                }

                img {
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
            }
        }  
    }
}


/********************/
/****** FOOTER ******/
/********************/
.landing-page {
    background: color('black');
}
.panels-flexible-landing_page {
    .panels-flexible-row-landing_page-2 {
        margin-top: 50px;
        background: color('black');
        text-align: center;
        color: color('white');
        text-transform: uppercase;

        a {
            color: color('white');
        }
    }
}

/**********************/
/****** MESSAGES ******/
/**********************/
.panels-flexible-landing_page {


    .messages--ok-color, .messages, .messages--status, .ok, .revision-current {
        background-color: lighten(green, 70%);
        color: green;
        border: 1px solid green;

        .messages__icon path {
            fill: green;
        }
    }

    .panels-flexible-row-first {
        position: relative;
        z-index: 9;
    }
    .pane-page-messages {
        position: absolute;
        left: 15px;
        right: 15px;
        width: 100%;
        z-index: 10;

        .messages {
            margin: 0 auto;
            p {
                margin: 0;
            }
        }
        
        // offset top padding
        top: -60px;
        @media all and (min-width: $col-sm) and (max-width: $col-md){
            top: -150px;
        }
        @media all and (min-width: $col-md) and (max-width: $col-lg){
            top: -130px;
        }
        @media all and (min-width: $col-lg) {
            top: -120px;
        }

    }
}


/******************/
/****** FORM ******/
/******************/
.panels-flexible-landing_page {
    // fields
    input[type="text"],
    input[type="email"],
    textarea {
        width: 100%;
        background: none;
        border: 1px solid color('black');
        color: color('black');

        &:hover,
        &:active,
        &:focus {
            border: 1px solid color('blue');
        }
    }
    // button
    input[type="submit"] {
        width: 100%;
        border-radius: 0px;
        text-align: center;
        background: color('maroon');
        text-transform: uppercase;
        letter-spacing: 0.25em;

        &:hover,
        &:active,
        &:focus {
            background: color('blue');
        }
    }
}